<template>
  <div class="container">
      <div @click="goback" style="font-size:14px;margin-bottom:10px;cursor: pointer;width:50px">
      <i class="el-icon-back"></i>
      返回
    </div>
    <div class="search" style="display:flex">
      <el-form ref="form" style="flex:1" :inline="true" :model="search" class="demo-form-inline">
        <el-form-item>
          <el-input v-model.trim="search.kname" @change="Search" placeholder="模糊搜索名称"></el-input>
        </el-form-item>
        <el-form-item>
          <el-input v-model.trim="search.name" @change="Search" placeholder="元素名称"></el-input>
        </el-form-item>
      </el-form>
      <div>
        <el-button type="primary" size="small" @click="create">新建</el-button>
      </div>
    </div>
    <!-- 列表 -->
    <el-table border v-bind:data="list">
      <!-- <el-table-column label="序号" type="index" width="50"></el-table-column> -->
      <el-table-column label="元素名称">
        <template v-slot="scope">
          <span>{{ scope.row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="元素内容">
        <template v-slot="scope">
          <span>{{ scope.row.element_content }}</span>
        </template>
      </el-table-column>
      <el-table-column label="名称说明">
        <template v-slot="scope">
          <span>{{ scope.row.name_explain }}</span>
        </template>
      </el-table-column>
      <el-table-column label="页面编码">
        <template v-slot="scope">
          <span>{{ pagenamelist[scope.row.page_code] }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="300px">
        <template v-slot="scope">
          <el-button type="danger" size="small" @click="deletepageele(scope.row.code)">删除</el-button>
          <el-button type="primary" size="small" @click="editpageEle(scope.row)">修改</el-button>
          <router-link style="margin-left: 10px" :to="'/iotmanage/multilanguage/pageelement/eletrans/' +scope.row.code+'&'+search.page_code">
            <el-button type="info" size="small">翻译</el-button>
          </router-link>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @current-change="handleCurrentChange"
      v-model:currentPage="search.page"
      :page-size="search.limit"
      layout="total, prev, pager, next"
      :total="total">
    </el-pagination>

    <!-- 新增修改 -->
    <el-dialog :title="title+'页面元素信息'" v-model="dialogFormVisible" width="600px">
      <el-form :model="form" :rules="rules" ref="form">
        <el-form-item label="元素名称" prop="name" label-width="100px">
          <el-input v-model="form.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="元素内容" prop="element_content" label-width="100px">
          <el-input v-model="form.element_content" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="名称说明" prop="name_explain" label-width="100px">
          <el-input v-model="form.name_explain" autocomplete="off"></el-input>
        </el-form-item>
        <!-- <el-form-item label="页面编码" label-width="100px">
          <el-input v-model="form.page_code" autocomplete="off"></el-input>
        </el-form-item> -->
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" v-if="!edit" @click="Validate(addconfirm)">新增</el-button>
          <el-button type="primary" v-if="edit" @click="Validate(editconfirm)">修改</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script type="text/ecmascript-6">
export default {
  name: '',
  mixins: [],
  components: {},
  props: {},
  data () {
    return {
      title: '',
      search: {
        page: 1,
        limit: 10,
        kname: '',
        name: '',
        page_code: '',
      },
      list: [],
      total: 0,
      edit: false,
      dialogFormVisible: false,
      form: {
        code: '',
        name: '',
        element_content: '',
        name_explain: '',
        page_code: '',
      },
      rules: {
        name: [{ required: true, message: '请输入元素名称', trigger: 'blur' }],
        element_content: [{ required: true, message: '请输入元素内容', trigger: 'blur' }],
        name_explain: [{ required: true, message: '请输入名称说明', trigger: 'blur' }],
      },
      pagenamelist: {}
    }
  },
  computed: {},
  watch: {},
  methods: {
    handleCurrentChange(val) {
      this.search.page = val
      this.Search()
    },
    GetPagname () {
      this.pagenamelist = {}
      this.api.MultiLanguageList({
        page: 1,
        limit: 100,
      }).then(res => {
        if (res.data.code == 200) {
          res.data.data.data.forEach(item => {
            this.pagenamelist[item.code] = item.name
          })
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    Search (){
      this.api.ElementList(this.search).then(res => {
        if (res.data.code == 200) {
          this.list = res.data.data.data
          this.total = res.data.data.page_info.total
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    create () {
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
      this.form = {
        code: '',
        name: '',
        element_content: '',
        name_explain: '',
        page_code: '',
      }
      this.title = '新增'
      this.edit = false
      this.dialogFormVisible = true
    },
    goback (){
      this.$router.push('/iotmanage/multilanguage')
    },
    editpageEle (item) {
      this.edit = true
      this.$nextTick(() => {
        this.$refs.form.resetFields()
      })
      this.title = '修改'
      this.api.ElementDetail({
        code: item.code
      }).then(res => {
         if (res.data.code == 200) {
          this.dialogFormVisible = true
          this.form = res.data.data
          return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    deletepageele(item) {
      this.$confirm('是否删除该页面元素?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // 删除请求
        this.api.ElementDelete({
          code: item
        }).then(res => {
          if (res.data.code == 200) {
            this.Search()
            this.$message.success('删除成功')
            return
          }
          this.$message.error('删除失败'+ res.data.msg)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消删除'
        });
      })
    },
    Validate: function (call) {
      this.$refs['form'].validate(res => {
        if (res && call) {
          call()
        }
      })
    },
    addconfirm () {
      this.update('add')
    },
    update (item){
      if (item=='add')this.form.page_code = this.$route.params.page
      this.api.ElementUpdate(this.form).then(res => {
        if (res.data.code == 200) {
          this.Search()
          this.$message.success(this.title + '成功')
          this.dialogFormVisible = false
          return
        }
        this.$message.error(this.title + '失败' + res.data.msg)
      })
    },
    editconfirm () {
      this.update()
    },
  },
  filters: {},
  mounted () {
    this.search.page_code = this.$route.params.page
    this.Search()
  },
  created () {
    this.GetPagname()
  }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
</style>
